import { __assign } from "tslib";
import { msdyn365Commerce } from '@msdyn365-commerce/core';
import classnames from 'classnames';
import * as React from 'react';

var PriceComponentActions = {};
var Price = function (props: any) {
    if (props.data.price.CustomerContextualPrice === undefined) {
        return null;
    }
    return (React.createElement("span", { className: classnames('msc-price', props.className) }, showStrikethroughPricing(props) ? renderCurrentPriceWithOriginalPrice(props) : renderCurrentPrice(props)));
};
var showStrikethroughPricing = function (props: any) {
    var originalPrice = getOriginalPrice(props);
    if (originalPrice && props.data.price.CustomerContextualPrice) {
        return originalPrice > props.data.price.CustomerContextualPrice;
    }
    return false;
};
var getOriginalPrice = function (props: any) {
    return Math.max(props.data.price.BasePrice || 0, props.data.price.TradeAgreementPrice || 0, props.data.price.AdjustedPrice || 0);
};
var renderCurrentPrice = function (props: any) {
    var initialPrice = formatCurrency(props, props.data.price.CustomerContextualPrice);
    return (React.createElement("span", { className: 'msc-price__actual', itemProp: 'price' }, initialPrice));
};
var formatCurrency = function (props: any, price: any) {
    if (price === undefined) {
        return undefined;
    }
    if (price === 0 && props.freePriceText) {
        return props.freePriceText;
    }
    let returnPrice = `$${price}`;
    try {
        var formatter = new Intl.NumberFormat(props.context.cultureFormatter.locale, {
            style: 'currency',
            currency: props.context.cultureFormatter.currencyCode,
            currencyDisplay: 'narrowSymbol'
          });
        returnPrice = formatter.format(price)

    } catch {}
    return returnPrice;
};
var renderCurrentPriceWithOriginalPrice = function (props: any) {
    var originalPrice = getOriginalPrice(props);
    var initialPrice = formatCurrency(props, originalPrice);
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { className: 'sr-only' },
            ' ',
            props.originalPriceText,
            ' ',
            initialPrice,
            ' ',
            props.currentPriceText,
            ' ',
            renderCurrentPrice(props)),
        React.createElement("span", { className: 'msc-price__strikethrough', "aria-hidden": 'true' }, initialPrice),
        React.createElement("span", { "aria-hidden": 'true' }, renderCurrentPrice(props)),
        props.savingsText && React.createElement("span", { className: 'msc-price__savings' }, props.savingsText)));
};

const PriceComponent: React.FunctionComponent<any> = msdyn365Commerce.createComponent<any>(
    'Product',
    { component: msdyn365Commerce.createComponent('Price', __assign({ component: Price }, PriceComponentActions)) }
);

export default PriceComponent