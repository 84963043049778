import { getProductPageUrlSync } from '@msdyn365-commerce-modules/retail-actions';
import { format } from '@msdyn365-commerce-modules/utilities';
import { RatingComponent } from '@msdyn365-commerce/components';
import { IComponentProps, ICoreContext, IGridSettings, IImageData, IImageSettings, Image, msdyn365Commerce } from '@msdyn365-commerce/core';
import { ProductPrice, ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import React from 'react';
import PriceComponent from '../../../../components/price-component';

export interface IProductComponentProps extends IComponentProps<{ product?: ProductSearchResult }> {
    className?: string;
    imageSettings?: IImageSettings;
    savingsText?: string;
    freePriceText?: string;
    originalPriceText?: string;
    currentPriceText?: string;
    ratingAriaLabel?: string;
}

const ProductCard: React.FC<IProductComponentProps> = ({
    data,
    context,
    imageSettings,
    savingsText,
    freePriceText,
    originalPriceText,
    currentPriceText,
    ratingAriaLabel,
    typeName,
    id
}) => {
    const product = data.product;
    if (!product) {
        return null;
    }

    let imageOrientation: string = '';
    if (product.AttributeValues!.length > 0) {
        product.AttributeValues!.map(property => {
            if (property.Name === 'Image Orientation') {
                imageOrientation = property.TextValue!;
            }
        });
    }
    const productUrl = getProductPageUrlSync(product.Name || '', product.RecordId, context && context.actionContext, undefined);
    const productImageSettings = imageOrientation === 'Landscape' ? getLandscapeImageSettings(imageSettings) : imageSettings;

    // Construct telemetry attribute to render
    const attribute = context
        && context.telemetry
        && context.telemetry.setTelemetryAttribute
        && context.telemetry.setTelemetryAttribute(product.RecordId.toString(), {
            pid: product.RecordId,
            pname: product.Name,
            mname: id
        }
        );
    return (
        <a href={productUrl} aria-label={renderLabel(product.Name, context.cultureFormatter.formatCurrency(product.Price), product.AverageRating, ratingAriaLabel)} className='msc-product' {...attribute}>
            <div className='msc-product__image'>
                {renderProductPlacementImage(productImageSettings, context.request.gridSettings, product.PrimaryImageUrl, product.Name)}
            </div>
            <div className='msc-product__details'>
                <h4 className='msc-product__title'>{product.Name}</h4>
                {renderPrice(context, typeName, id, product.BasePrice, product.Price, savingsText, freePriceText, originalPriceText, currentPriceText)}
                {renderDescription(product.Description)}
                {renderRating(context, typeName, id, product.AverageRating, product.TotalRatings, ratingAriaLabel)}
            </div>
        </a>
    );
};

function renderLabel(name: string = '', price: string = '', rating?: number, ratingAriaLabel?: string): string {
    let ratingLabel = '';

    if (rating) {
        const roundedRating = rating.toFixed(2);
        ratingLabel = format(ratingAriaLabel || '', roundedRating, '5');
    }

    return (`${name} ${price} ${ratingLabel}`);
}

function renderDescription(description?: string): JSX.Element | null {
    return <p className='msc-product__text'>{description}</p>;
}

function renderRating(context: ICoreContext, typeName: string, id: string, avgRating?: number, totalRatings?: number, ariaLabel?: string): JSX.Element | null {
    if (!avgRating) {
        return null;
    }

    const numRatings = totalRatings && totalRatings.toString() || undefined;

    return (
        <RatingComponent
            context={context}
            id={id}
            typeName={typeName}
            avgRating={avgRating}
            ratingCount={numRatings}
            readOnly={true}
            ariaLabel={ariaLabel || ''}
            data={{}}
        />
    );
}

function renderPrice(
    context: ICoreContext,
    typeName: string,
    id: string,
    basePrice?: number,
    adjustedPrice?: number,
    savingsText?: string,
    freePriceText?: string,
    originalPriceText?: string,
    currentPriceText?: string): JSX.Element | null {
    const price: ProductPrice = {
        BasePrice: basePrice,
        AdjustedPrice: adjustedPrice,
        CustomerContextualPrice: adjustedPrice
    };

    return (
        <PriceComponent
            context={context}
            id={id}
            typeName={typeName}
            data={{ price: price }}
            savingsText={savingsText}
            freePriceText={freePriceText}
            originalPriceText={originalPriceText}
        />
    );
}

function renderProductPlacementImage(imageSettings?: IImageSettings, gridSettings?: IGridSettings, imageUrl?: string, altText: string = ''): JSX.Element | null {
    if (!imageUrl || !gridSettings || !imageSettings) {
        return null;
    }
    const img: IImageData = {
        src: imageUrl,
        altText: altText
    };
    const imageProps = {
        gridSettings: gridSettings,
        imageSettings: imageSettings
    };
    return (
        <Image {...img} {...imageProps} loadFailureBehavior='empty' />
    );
}

function getLandscapeImageSettings(cmsImageSettings?: IImageSettings): IImageSettings {
    // tslint:disable-next-line: no-unnecessary-local-variable
    const landscapeImageSettings: IImageSettings = {
        viewports: {
            xs: { q: 'w=390&h=292&q=80&m=6&f=jpg', w: 390, h: 292 },
            sm: { q: 'w=528&h=396&q=80&m=6&f=jpg', w: 528, h: 396 },
            md: { q: 'w=640&h=479&q=80&m=6&f=jpg', w: 640, h: 479 },
            lg: { q: 'w=584&h=437&q=80&m=6&f=jpg', w: 584, h: 437 },
            xl: { q: 'w=702&h=526&q=80&m=6&f=jpg', w: 702, h: 526 }
        },
        disableLazyLoad: cmsImageSettings && cmsImageSettings.disableLazyLoad,
        lazyload: cmsImageSettings && cmsImageSettings.lazyload
    };

    return landscapeImageSettings;
}

// @ts-ignore
export const ProductComponent: React.FunctionComponent<IProductComponentProps> = msdyn365Commerce.createComponent<IProductComponentProps>(
    'Product',
    { component: ProductCard }
);