import { IProductCollectionViewProps } from '@msdyn365-commerce-modules/product-collection';
import { INodeProps, Module, Node, NodeTag } from '@msdyn365-commerce-modules/utilities';
import { ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import * as React from 'react';
import {ProductComponent} from './components/product-collection-component';

const ProductCollectionView: React.FC<IProductCollectionViewProps> = props => {
    const { heading, ProductCollectionContainer, products, SingleSlideCarouselComponentProps, GridComponentProps, isCarousel } = props;
    if (products) {
        return (
            <Module {...ProductCollectionContainer}>
                {heading}
                {
                    isCarousel ? _renderCarousel(SingleSlideCarouselComponentProps,props.config.productCollection.products,props) :
                     _renderGrid(GridComponentProps, props.config.productCollection.products,props)
                }
            </Module>
        );
    }
    return null;
};

const _renderCarousel = (carouselContainer: INodeProps, items: ProductSearchResult[],PropDetails: React.PropsWithChildren<IProductCollectionViewProps>): JSX.Element => {
    const isMobile = PropDetails.context && PropDetails.context.request && PropDetails.context.request.device.Type === 'Mobile'
    let mobileCarouselContainer = {
        tag: 'ul' as NodeTag,
        className: 'ms-product-collection__custom-carousel'
    }
    return (
        <Node {...(isMobile ? mobileCarouselContainer : carouselContainer)}>
            {items && items.map((item,index)=>_renderProduct(item,PropDetails,index))}
        </Node>
    );
};

const _renderGrid = (gridContainer: INodeProps, items: ProductSearchResult[],PropDetails: React.PropsWithChildren<IProductCollectionViewProps>): JSX.Element => {
    return (
        <Node {...gridContainer}>
            {items && items.map((item,index)=>_renderProduct(item,PropDetails,index))}
        </Node>
    );
};

const _renderProduct = (product: ProductSearchResult,PropDetails: React.PropsWithChildren<IProductCollectionViewProps>,index: number): JSX.Element => {
 const liClass = 'ms-product-collection__item';
 const orientationClass = getImageOrientation(product) === 'Landscape'? 'product-placement__item-limage' : 'product-placement__item-pimage';
 return (
        <li className={`${liClass} ${orientationClass}`} key={index} >
                    <ProductComponent
                        context={PropDetails.context}
                        imageSettings={PropDetails.config.imageSettings}
                        freePriceText={PropDetails.resources.priceFree}
                        originalPriceText={PropDetails.resources.originalPriceText}
                        currentPriceText={PropDetails.resources.currentPriceText}
                        ratingAriaLabel={PropDetails.resources.ratingAriaLabel}
                        id={PropDetails.id}
                        typeName={PropDetails.typeName}
                        data={{product: product}}
                    />
                </li>
    );
};

function getImageOrientation(product: ProductSearchResult): string {
    let imageOrientation: string ='';
    if (product.AttributeValues!.length > 0) {
        product.AttributeValues!.map(property => {
            if (property.Name?.toLowerCase() === 'image orientation') {
                imageOrientation = property.TextValue!;
            }
        });
    }
    return imageOrientation;
}

export default ProductCollectionView;